import '../scss/main.scss';

import Swal from 'sweetalert2';

const general = {
  init: function () {
    document.addEventListener('DOMContentLoaded', () => {
      this.copyText();

      $('[href="#"]').on('click', function (event) {
        event.preventDefault();
      });
    });
  },

  copyText: function () {
    $('[data-text-to-copy]').click(copySelectedText);

    function copySelectedText(e) {
      const textToCopy = $(e.target).data('text-to-copy');

      console.log(textToCopy);

      const tempInput = $('<textarea>');
      $('body').append(tempInput);
      tempInput.val(textToCopy).select();
      document.execCommand('copy');
      tempInput.remove();

      general.showAlert('Текст скопирован');
    }
  },

  showAlert: function (text, time = 1000) {
    Swal.fire({
      icon: 'success',
      title: text,
      showConfirmButton: false,
      timer: time,
    });
  },
};

general.init();
